import '@unocss/reset/tailwind.css'

import '../styles/globals.scss'
import type { AppProps } from 'next/app'
import Footer from '../layouts/Footer'
import Head from 'next/head'
import { fetcherAll } from '../utils/fetcher'
import useSWR from 'swr'
import { useEffect, useState } from 'react'
import { initializeBraze } from '../utils/braze'
function App({ Component, pageProps }: AppProps) {
  // TODO: define user type
  const [currentUser, setCurrentUser] = useState<any>(null)
  // 現在のユーザー情報を取得
  const {
    data: user,
    isValidating,
    isLoading,
  } = useSWR(
    [`${process.env.NEXT_PUBLIC_API_URL}/api/v1/users/current`],
    fetcherAll
  )

  useEffect(() => {
    if (!isValidating && !isLoading) {
      if (user) {
        setCurrentUser(user)
        initializeBraze(user)
      } else {
        console.warn('User not found, initializing Braze without user')
        initializeBraze()
      }
    }
  }, [user, isValidating, isLoading])

  return (
    <>
      <Head>
        <title>SHElikes（シーライクス）無料体験レッスン受付中</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Head>
      <Component {...pageProps} currentUser={currentUser} />
      <Footer />
    </>
  )
}

App.getInitialProps = async () => ({ pageProps: {} })

export default App
