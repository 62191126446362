const API_KEY = process.env.NEXT_PUBLIC_BRAZE_API_KEY
const ENDPOINT = process.env.NEXT_PUBLIC_BRAZE_ENDPOINT

export const initializeBraze = async (user?: any) => {
  if (!API_KEY || !ENDPOINT || typeof window === 'undefined') return

  const braze = await import('@braze/web-sdk')

  // Initialize the Braze SDK
  if (!braze.isInitialized()) {
    braze.initialize(API_KEY, {
      baseUrl: ENDPOINT,
    })
  }

  // Optionally set the current user's external ID before starting a new session
  if (user && user[0]?.id) {
    braze.changeUser(user[0].id)
  }

  // Open the session
  braze.openSession()
}

export const sendPurchaseLog = async (eventReservation: {
  reservationId: string
  lessonId: string
  lessonDateAndTime: string
  utm_source?: string | string[]
  utm_medium?: string | string[]
  utm_campaign?: string | string[]
  utm_term?: string | string[]
  utm_content?: string | string[]
}) => {
  const braze = await import('@braze/web-sdk')

  const now = new Date()
  const formattedCurrentDate = `${now.getFullYear()}-${String(
    now.getMonth() + 1
  ).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(
    now.getHours()
  ).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`

  // Send a custom event to Braze
  braze.logCustomEvent('likes_trial_lessons', {
    体験レッスン予約ID: eventReservation.reservationId,
    申込日時: formattedCurrentDate,
    体験レッスンID: eventReservation.lessonId,
    体験レッスン開催日時: eventReservation.lessonDateAndTime,
    utm_source: eventReservation.utm_source,
    utm_medium: eventReservation.utm_medium,
    utm_campaign: eventReservation.utm_campaign,
    utm_term: eventReservation.utm_term,
    utm_content: eventReservation.utm_content,
  })

  // Request immediate data flush
  braze.requestImmediateDataFlush()
}
