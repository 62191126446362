import type { NextPage } from 'next'
import Link from 'next/link'
import styles from './index.module.scss'

const Footer: NextPage = () => {
  return (
    <footer className={styles.container} id="likes-footer">
      <div className={styles.section}>
        <div>
          <div className={styles.link}>
            <div className={styles.linkTitle}>
              <a href="https://she-inc.co.jp/">
                SHE | ミレニアルライフコーチングカンパニー
              </a>
            </div>
            <div className={styles.linkContent}>
              <ul>
                <li>
                  <a
                    href="https://shelikes.jp/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SHElikes
                  </a>
                </li>
                <li>|</li>
                <li>
                  <a
                    href="https://shares.shelikes.jp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SHEshares
                  </a>
                </li>
                <>
                  <li>|</li>
                  <li>
                    <a
                      href="https://money.shelikes.jp/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      SHEmoney
                    </a>
                  </li>
                </>
                <li>|</li>
                <li>
                  <a
                    href="https://sheworks.shelikes.jp/?utm_source=likes&utm_medium=lp&utm_campaign=footer_202308"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SHE WORKS
                  </a>
                </li>
                <li>|</li>
                <li>
                  <a
                    href="https://shelikes.jp/multicreator/?utm_source=likes&utm_medium=lp&utm_campaign=mcc_likesfooter"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SHElikes MULTI CREATOR COURSE Designer
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.link}>
            <div className={styles.linkTitle}>SHEに関する情報</div>
            <div className={styles.linkContent}>
              <ul>
                <li>
                  <a
                    target="_blank"
                    href="https://she-inc.co.jp/about"
                    rel="noreferrer"
                  >
                    運営会社
                  </a>
                </li>
                <li>|</li>
                <li>
                  <a
                    target="_blank"
                    href="https://she-inc.co.jp/common/agreement.html"
                    rel="noreferrer"
                  >
                    利用規約集
                  </a>
                </li>
                <li>|</li>
                <li>
                  <a
                    target="_blank"
                    href="https://shelikes.jp/pricing"
                    rel="noreferrer"
                  >
                    料金プラン
                  </a>{' '}
                </li>
                <li>|</li>
                <li>
                  <Link href="https://support.shelikes.jp/">お問い合わせ</Link>
                </li>
                <li>|</li>
                <li>
                  <a
                    target="_blank"
                    href="https://she-inc.co.jp/common/policy.html"
                    rel="noreferrer"
                  >
                    プライバシーポリシー
                  </a>
                </li>
                <li>|</li>
                <li>
                  <a
                    target="_blank"
                    href="https://she-files-public.s3-ap-northeast-1.amazonaws.com/%E7%89%B9%E5%AE%9A%E5%95%86%E5%8F%96%E5%BC%95%E6%B3%95%E3%81%AB%E5%9F%BA%E3%81%A4%E3%82%99%E3%81%8F%E8%A1%A8%E7%A4%BA.pdf"
                    rel="noreferrer"
                  >
                    特定商取引法に基づく表示
                  </a>
                </li>
                <li>|</li>
                <li>
                  <a
                    target="_blank"
                    href="https://sheinc.notion.site/SHE-d7b37bfd7fb64b4cbba5c0391b5e4041"
                    rel="noreferrer"
                  >
                    コミュニケーションポリシー
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p>© SHE inc. All Rights Reserved.</p>
      </div>
    </footer>
  )
}

export default Footer
